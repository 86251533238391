<template>
    <main class="main mt-5" >
        <div class="container" dir="rtl">
            <div class="row">
                 <aside class="sidebar-product col-lg-3 col-xl-2 col-md-3 padding-left-lg mobile-sidebar mt-2" style="margin-left: auto;">
                    <div class="sidebar-wrapper">
                        <div class="widget">
                            <h3 class="widget-title">
                                <a data-toggle="collapse" href="#widget-body-2" role="button" aria-expanded="true" aria-controls="widget-body-2">افضل خمس منتجات تم بيعها</a>
                            </h3>
                            <div class="collapse show" id="widget-body-2">
                                <div class="widget-body">
                                    <div class="product-intro" v-for="(bestSeller, index) in bestSellers" :key="index">
                                        <div class="product-default left-details product-widget">
                                            <figure>
                                                <a href="product.html">
                                                    <img :src="base_url+'/api/public/file/download/'+bestSeller.imageUrl">
                                                </a>
                                            </figure>
                                            <div class="product-details">
                                                <h2 class="product-title">
                                                    <a href="product.html">{{ bestSeller.name }}</a>
                                                </h2>
                                                <div class="ratings-container">
                                                    <div class="product-ratings">
                                                        <span class="ratings" style="width:100%"></span><!-- End .ratings -->
                                                        <span class="tooltiptext tooltip-top"></span>
                                                    </div><!-- End .product-ratings -->
                                                </div><!-- End .product-container -->
                                                <div class="price-box" v-if="bestSeller.isSale">
                                                    <span class="old-price">{{bestSeller.price}}LYD </span>
                                                    <span class="product-price">{{ bestSeller.salePrice+" LYD" }} </span>
                                                </div>
                                                <div class="price-box" v-else>
                                                    <span class="product-price">{{ bestSeller.price }} LYD</span>
                                                </div><!-- End .price-box -->
                                            </div><!-- End .product-details -->
                                        </div>
                                    </div>
                                </div><!-- End .widget-body -->
                            </div><!-- End .collapse -->
                        </div><!-- End .widget -->
                    </div>
                </aside><!-- End .col-md-3 -->
                <div class="col-lg-9 col-md-9 stores" style="padding: 40px;">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-flex">
                                <input type="search" v-model="searchVendor" class="form-control rounded" placeholder="البحث عن متجر.." aria-label="Search"
                                v-on:keyup="search" />
                            </div>
                            <hr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-12 col-lg-12" v-if="loading == true">
                            <div class="row">
                                <div v-for="vendor in vendors" :key="vendor.id" class="col-md-3">
                                    <div v-bind:class="vendor.vendorStatus == 'OPEN'? 'card card-colored mb-3':'card card-colored card-disabled mb-3'" >
                                        <div class="card-header">
                                        <div>
                                            <div v-if="vendor.vendorStatus == 'OPEN'" class="store-header">
                                                <p><font-awesome-icon icon="check" color="green" /> مفتوح</p>
                                            </div>
                                            <div v-else class="store-header">
                                                <p><i class="fa fa-times" style="margin-right: -16px; margin-top: 2px;"></i> مغلق</p>
                                            </div>
                                        </div>
                                        </div>

                                        <a :href="'/products/vendors/'+vendor.id" :id="'imagePr'+vendor.id" 
                                        class="display-none card-img-top cursor"
                                        >
                                            <img class="card-img-top cursor" @load="loaded(vendor.id)" :src="base_url+'/api/public/file/download/'+vendor.imageUrl" alt="Card image cap">
                                        </a>
                                        <div :id="'imagePrLoad'+vendor.id" class="card-img-top cursor" >
                                            <b-skeleton  type="avatar" class="card-img-top cursor" height="145px" width="145px"></b-skeleton>
                                        </div>
                                        <h4 class="text-center text-white mb-1"><font-awesome-icon icon="map-marker-alt"></font-awesome-icon> {{vendor.zoneName == null ? 'طرابلس': vendor.zoneName}}</h4>
                                        <div class="card-header">
                                            <a  :href="'/products/vendors/'+vendor.id" v-if="vendor.vendorStatus == 'OPEN'" class="store-header">
                                                <i class="fa fa-arrow-right" style="margin-left: auto;"></i>
                                                <h4>{{ vendor.name}}</h4>
                                            </a>
                                            <div v-else class="store-header">
                                                <h4>{{ vendor.name}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col-md-12 col-lg-12" v-if="loading == false">
                            <div class="row">
                                <div v-for="(n, i) in 8" :key="i" class="col-md-3">
                                    <div v-bind:class="'card card-colored mb-3'" >
                                        <div class="card-header">
                                            <div class="store-header">
                                                <b-skeleton animation="wave" width="32%"></b-skeleton>
                                            </div>
                                        </div>
                                        <b-skeleton type="avatar" class="card-img-top cursor" height="145px" width="145px"></b-skeleton>
                                        <h4 class="text-center text-white mb-1"><b-skeleton animation="wave" width="32%"></b-skeleton></h4>
                                        <div class="card-header">
                                            <div class="store-header">
                                                <b-skeleton animation="wave" width="32%"></b-skeleton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <pagination dir="rtl" v-model="page" :records="countVendors" :per-page="8" @paginate="setPage"/>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

export default {
    data() {
        return {
            searchVendor: '',
            currentPage : 0,
            page : 1,
            previosPage : 0,
            countVendors: 0,
            vendors: [],
            bestSellers   : [],
            loading   : false,
            base_url   : process.env.VUE_APP_URL,
        }
    },
    mounted() {
        this.getAllProducts();
        this.axios
        .get(process.env.VUE_APP_URL+'/api/public/products/best-seller?size=5',{
            headers: {'Access-Control-Allow-Origin': '*'}
        })
        .then(response => {
            this.bestSellers  = response.data
        })
    },
    methods: {
        getAllProducts: function() {
            this.axios({
                url: 
process.env.VUE_APP_URL+'/api/public/vendors?page=0&size=8',
                method: 'GET',
                headers: {'Access-Control-Allow-Origin': '*'},
            })
            .then(response => {
                this.vendors = response.data
                this.countVendors = parseInt(response.headers["x-total-count"]);
                this.loading = true
            })
        },
        search: function() {
            this.loading = false
            this.page = 1;
            if(this.searchVendor == '') {
                this.getAllProducts();
            } else {
                this.axios({
                    url: 
process.env.VUE_APP_URL+'/api/public/vendors?page=0&size=8&name.contains='+this.searchVendor,
                    method: 'GET',
                    headers: {'Access-Control-Allow-Origin': '*'},
                })
                .then(response => {
                    this.vendors = response.data
                    this.countVendors = parseInt(response.headers["x-total-count"]);
                    this.loading = true
                })
            }
        },
        setPage: function(page) {
            this.loading = false
            page = page-1;
            this.axios({
                url: 
process.env.VUE_APP_URL+'/api/public/vendors?page='+page+'&size=8',
                method: 'GET',
                headers: {'Access-Control-Allow-Origin': '*'},
            })
            .then(response => {
                this.vendors = response.data
                this.loading = true
                this.countVendors = parseInt(response.headers["x-total-count"]);
            })
        },
        loaded: function(vendorId) {
            document.getElementById(`imagePrLoad${vendorId}`).style.display = "none"
            document.getElementById(`imagePr${vendorId}`).style.display = "block"
        }
    },
}
</script>

<style scoped>

    .white {
        background: white;
    }
    .store-header {
        display: flex;
        justify-content: space-between;
    }
    .stores {
        background-color: white;
        border-radius: 16px;
        margin-bottom: 38px;
    }
    .col-9 {
        background-color: rgb(255, 255, 255);
        border-radius: 1rem;
        padding: 31px !important;
        margin-left: 4rem;
        margin-bottom: 4rem;
    }
    .card-img-top {
        height: unset;
        width: 145px;
        align-self: center;
        padding: 10px;
        border-radius: 79px;
    }
    .card-header {
        margin: 0;
        padding: 1.2rem 1.5rem;
        border-radius: 0;
        border-color: #ddd0;
        background-color: #29364b21;
        color: #ffffff;
        font-weight: 700;
        line-height: 1.5;
        text-transform: uppercase;
        direction: rtl;
    }
    .card-colored {
        background-image: linear-gradient(to right top, rgb(41, 54, 75), rgb(185, 51, 110));
        border-radius: 15px;
        border-width: 7px;
        border-color: #e6e6e6;
    }
    .card-disabled {
        background-image: linear-gradient(to right top, rgb(110 123 144), rgb(226 194 208));        border-radius: 15px;
        border-width: 7px;
        border-color: #e6e6e6;
        opacity: 0.6;
    }
    .store-header{
        display: flex;
        justify-content: center;
    }
    .fa-times {
        color: #ffffff;
    }
    p {
        margin-bottom: 0;
    }
    hr {
        max-width: 1730px;
        margin: 1.5rem auto 4.2rem;
        border: 0;
        border-top: 1px solid #dfdfdf;
    }
</style>